@import '../node_modules/normalize.css/normalize.css';
@import '../node_modules/spectre.css/dist/spectre.min.css';
@import url('https://fonts.googleapis.com/css2?family=Inconsolata&family=Syne+Mono&display=swap');

$code-font: 'Inconsolata', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
$code-font2: 'Syne Mono', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

html {
    background-color: #efefef;
    font-size: 1.2rem;
}

body {
    background: none;

    ._page_title {
        font-family: $code-font2;
        margin: 5px;
        span {
            padding: 0px 5px;
        }
    }

    ._search {
        padding: 15px 5px 0 0;
        background-color: none;
        input {
            font-family: $code-font;
            padding: 0px 10px;
            width: 100%;
            border-style: solid;
            border-width: 2px;
            background: url('./icons8-search.svg') no-repeat right top;
            background-size: contain;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: solid 2px;
            border-color: #ccc;
            
            &:focus {
                outline: none;
                border-color: #666;
            }
            &::placeholder {
                color: #ccc;
            }
        }
    }

    ._card {
        min-width: 300px;
        max-width: 50%;

        ._title {
            font-family: $code-font;
        }

        ._card_body {
            font-size: 0.7rem;
        }

        code {
            font-size: 0.75rem;
            font-family: $code-font;
        }

        ._card_tag {
            background-color: #666;
            color: #fff;
            margin: 1px;
            padding: 1px 4px;
            cursor: pointer;
            &:hover {
                background-color: #000;
            }
        }

        ._card_time {
            width: 100%;
            margin-top: 5px;
            text-align: center;
        }
    }
}
